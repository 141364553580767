export const GA_TRACKING_ID = process.env.GTM_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    console.log("test gtg", url);
    if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push({
            event: "gtm.load",
            page_location: url,
            page: url,
        })
  } else {
    console.log({
      event: "pageview",
      page: url,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    })
}